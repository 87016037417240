import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "position"
    }}>{`Position`}</h1>
    <p>{`Use these shorthand utilities for quickly configuring the position of an element.`}</p>
    <p>{`As such, the classes are named using the format:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`position-{value} for xs`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`position-{breakpoint}-{value} for sm, md, lg, xl, and xxl.`}</inlineCode></li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="position-static">...</div>
<div class="position-relative">...</div>
<div class="position-absolute">...</div>
<div class="position-fixed">...</div>
<div class="position-sticky">...</div>
`}</code></pre>
    <p>{`Where value is one of:`}</p>
    <pre><code parentName="pre" {...{}}>{`static
absolute
fixed
relative
sticky
initial
inherit
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      